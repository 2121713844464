import React, { useState, useEffect, useRef } from 'react';
import ReactTags from 'react-tag-autocomplete';
import { useSelector } from 'react-redux';
import './EditTemplateModal.scss';
import '../TemplateModal/TemplateModal.scss';
import { useAppDispatch } from '../../../store';
import { EditingType, resetState, toggleEditingTemplateModal } from '../../../store/Templates/reducer';
import { defaultTagSuggestions, getDjangoKeyListFromObject } from '../../../utils/helpers';
import { createTemplate, deleteTemplateById, getAllTemplates, TemplateCreationObject, updateTemplateById, UpdateTemplateObject } from '../../../store/Templates/actions';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import { selectCreateTemplateSuccess, selectDeleteTemplateSuccess, selectEditingTemplate } from '../../../store/Templates/selectors';
import { SampleAgent, SampleContacts, SampleCustomer, SampleDeal, SampleLoanOfficer, SampleUser, SampleCashBuyer } from '../../../utils/SampleInterfaces';
import KeyMapModal from '../KeyMapModal/KeyMapModal';

const EditTemplateModal = ({ isEditing, editingType }: any) => {
  const editingTemplate = useSelector((state) => selectEditingTemplate(state));
  const deleteTemplateSuccess = useSelector((state) => selectDeleteTemplateSuccess(state));
  const createdTemplateSuccessfully = useSelector(state => selectCreateTemplateSuccess(state)) as boolean;
  const dispatch = useAppDispatch();
  const [template, setTemplate] = useState('');
  const [plainTemplate, setPlainTemplate] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]) as any[];
  const [contextArray, setContextArray] = useState([] as any);
  // Key Map
  const [roles, setRoles] = useState({});
  const [openKeyMapModal, setOpenKeyMapModal] = useState(false);
  // SSR issue
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [loadedCustomConfig, setLoadedCustomConfig] = useState(false);
  const editorRef = useRef() as any;
  const { ClassicEditor, CKEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
      ClassicEditor: require('@cleverrealestate/ckeditor5-build-classic'),
    }

    setLoadedEditor(true)
  }, [])
  // END SSR issue

  useEffect(() => {
    if (deleteTemplateSuccess) {
      dispatch(resetState());
      dispatch(getAllTemplates());
      onClose();
    }
  }, [deleteTemplateSuccess]);

  useEffect(() => {
    if (createdTemplateSuccessfully) {
      // refetch templates and close
      dispatch(getAllTemplates());
      dispatch(toggleEditingTemplateModal());
    }
  }, [createdTemplateSuccessfully])

  useEffect(() => {
    if (editingTemplate) {
      const newTags = editingTemplate.tags.map((tag: any) => {
        return {
          name: tag,
        }
      })
      if (editingType === EditingType.SMS) {
        setTemplate(editingTemplate.template.replaceAll(/\n/g, '<br>'));
      } else {
        setTemplate(editingTemplate.template);
      }
      setTags(newTags)
      setSubject(editingTemplate.subject);
      setName(editingTemplate.name);
      setDescription(editingTemplate.description);
      setPlainTemplate(editingTemplate.plain_template);
      setContextArray(editingTemplate.context);
      setRoles(editingTemplate.roles);
    }
  }, [editingTemplate])

  useEffect(() => {
    if (loadedEditor && ClassicEditor) {
      // get sample key lists for each models
      // const contactKeyList = getDjangoKeyListFromObject('contact', SampleContacts) as any[];
      // const customerKeyList = getDjangoKeyListFromObject('customer', SampleCustomer, 1) as any[];
      // const agentKeyList = getDjangoKeyListFromObject('agent', SampleAgent, 1) as any[];
      // const dealKeyList = getDjangoKeyListFromObject('deal', SampleDeal, 1) as any[];
      // const agent1KeyList = getDjangoKeyListFromObject('agent1', SampleAgent, 1);
      // const agent2KeyList = getDjangoKeyListFromObject('agent2', SampleAgent, 1);
      // const userKeyList = getDjangoKeyListFromObject('user', SampleUser, 1);
      // const loanOfficerKeyList = getDjangoKeyListFromObject('loan_officer', SampleLoanOfficer, 1) as any[];

      // const typeMap = ([] as any).concat.apply(
      //   ['footnotes'],
      //   [contactKeyList, customerKeyList, agentKeyList, dealKeyList, agent1KeyList, agent2KeyList, userKeyList, loanOfficerKeyList]);
      const typeMap = ['footnotes'];
      ClassicEditor.customTypes = typeMap
      ClassicEditor.defaultConfig = {
        ...ClassicEditor.defaultConfig,
        placeholderConfig: {
          types: ClassicEditor.customTypes,
        },
      }
      setLoadedCustomConfig(true);
    }
  }, [loadedEditor])

  useEffect(() => {
    if (!isEditing && editingType) {
      if (editingType === EditingType.Email) {
        setTags([
          {
            name: 'email',
          }
        ])
      } else if (editingType === EditingType.SMS) {
        setTags([
          {
            name: 'sms'
          }
        ])
      }
    }
  }, [editingType])

  const onDelete = (i: any) => {
    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTags(newTags);
  }

  const onAddition = (tag: any) => {
    const newTags = [].concat(tags, tag);
    setTags(newTags);
  }

  const onClose = () => {
    dispatch(toggleEditingTemplateModal())
  };

  const handleBlur = (event: any, editor: any) => {
    if (editingType === EditingType.SMS) {
      const value = editor.getData();
      let newOutput = value
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, ' ');
      editor.setData(newOutput);
      const plainText = viewToPlainText(editor.editing.view.document.getRoot());
      setPlainTemplate(plainText);
    } else {
      const plainText = viewToPlainText(editor.editing.view.document.getRoot());
      setPlainTemplate(plainText);
    }
  };

  const handleTemplateChange = (event: any, editor: any) => {
    const value = editor.getData();
    setTemplate(value);
    // Set Context if there is context values
    const contextregex = /{{\s([^.}}]+)\.[^}]+}}/gm
    const matches = [...value.matchAll(contextregex)];
    const contexts = matches.map((matchArray) => {
      if (matchArray.length > 1) {
        return matchArray[1]
      }
    })
    const uniqueContexts = [...new Set(contexts)];
    setContextArray(uniqueContexts as any);
    // Set the template
  }

  const updateTemplateWysiwyg = () => {
    setLoadedCustomConfig(false);
    const roleItems = Object.keys(roles).map((key) => {
      switch ((roles as any)[key]) {
        case 'Agent':
          return getDjangoKeyListFromObject(key, SampleAgent);
        case 'Loan Officer':
          return getDjangoKeyListFromObject(key, SampleLoanOfficer)
        case 'Customer':
          return getDjangoKeyListFromObject(key, SampleCustomer)
        case 'User':
          return getDjangoKeyListFromObject(key, SampleUser);
        case 'Cash Buyer':
          return getDjangoKeyListFromObject(key, SampleCashBuyer);
        default:
          return getDjangoKeyListFromObject(key, SampleDeal)
      }
    });
    const merged = roleItems.flat(1);
    
    const typeMap = ([] as any).concat.apply(
        ['footnotes'],
        [merged]
      )
    ClassicEditor.customTypes = typeMap
    ClassicEditor.defaultConfig = {
      ...ClassicEditor.defaultConfig,
      placeholderConfig: {
        types: ClassicEditor.customTypes,
      },
    }
    setLoadedCustomConfig(true);
  }

  useEffect(() => {
    if (loadedEditor && ClassicEditor) {
      updateTemplateWysiwyg();
    }
  }, [roles])

  const updateKeyMap = (keyMapItem: object) => {
    const newRoles = {
      ...roles,
      ...keyMapItem,
    }
    setRoles(newRoles);
    setOpenKeyMapModal(false);
  }

  const openKeyMap = () => {
    setOpenKeyMapModal(true);
    setLoadedCustomConfig(false);
  }

  const save = () => {
    if (tags.length > 0 && template !== '' && name !== '') {
      const templateCreationObject = {
        context: contextArray,
        description: description,
        plain_template: plainTemplate,
        name: name,
        subject: subject,
        roles: roles,
        tags: tags.map((tag: any) => tag.name),
        template: editingType === EditingType.SMS ? plainTemplate : template,
      } as TemplateCreationObject
      if (isEditing) {
        const updateObject = {
          id: editingTemplate.id,
          template: templateCreationObject,
        } as UpdateTemplateObject
        dispatch(updateTemplateById(updateObject));
      } else {
        dispatch(createTemplate(templateCreationObject));
      }
    }
  }

  const removeRole = (key: string) => {
    const newRoles = { ...roles }
    if (Object.keys(newRoles).length > 0) {
      delete (newRoles as any)[key]
    }
    const updateRoles = {...newRoles}
    setRoles(updateRoles);

    updateTemplateWysiwyg();
  }

  return (
    <>
      <div className="editModal">
        <div className="editModalContainer">
          <div className="templateRow">
            <h2>{isEditing ? 'Editing ' : 'Create '}Template</h2>
            <button className="close" onClick={onClose} />
          </div>
          <div className="templateFieldsContainer">
            <div className="field">
              <label>Tags</label>
              <ReactTags
                tags={tags}
                allowNew
                suggestions={defaultTagSuggestions}
                onDelete={onDelete}
                onAddition={onAddition}
              />
            </div>
            <div className="field">
              <label>Template Name*</label>
              <input value={name} className="defaultInput" onChange={(e) => setName(e.target.value)} />
            </div>
            {editingType && editingType === EditingType.Email && (
              <div className="field">
                <label>Email Subject</label>
                <input value={subject} className="defaultInput" onChange={e => setSubject(e.target.value)} />
              </div>
            )}
            <div className="field">
              <label>Description (Description of the template)</label>
              <textarea value={description} className="defaultTextArea" onChange={e => setDescription(e.target.value)} />
            </div>
            <div className="field">
              <div className="keyMapRow">
                <label>Template Roles</label>
                <button className="defaultButton" onClick={() => openKeyMap()}>Create new role</button>
              </div>
              <div className="roleItems">
                <div className="roleHeaders">
                  <h4>Role Name</h4>
                  <h4>Role Object Type</h4>
                </div>
                {Object.keys(roles).length > 0 && Object.keys(roles).map((key, index) => (
                  <div key={`role-${index}`} className="roleItem">
                    <p>{key}</p>
                    <p>{(roles as any)[key]}</p>
                    <div>
                      <button className="defaultButton smallText" onClick={() => removeRole(key)}>Remove Role</button>
                    </div>
                  </div>
                ))}
                {Object.keys(roles).length === 0 && <p>There are no roles yet</p>}
              </div>
            </div>
            <div className="field templateField">
              <label>Template*</label>
                {/* CKEditor here */}
                {/* Handle different contexts */}
                {editingType && loadedEditor && loadedCustomConfig && (
                  <CKEditor
                    onBlur={handleBlur}
                    editor={ClassicEditor}
                    data={template || ''}
                    onChange={handleTemplateChange}
                  />
                )}
            </div>
            <button onClick={() => save()} className="defaultButton">{isEditing ? 'Update Template' : 'Save New Template'}</button>
            {isEditing && <button className="deleteButtonLarge" onClick={() => dispatch(deleteTemplateById(editingTemplate.id))}>Delete</button>}
          </div>
        </div>
      </div>
      {openKeyMapModal && (
        <KeyMapModal onClose={() => setOpenKeyMapModal(false)} onSubmit={(keyMapItem: any) => updateKeyMap(keyMapItem)} />
      )}
    </>
  )
}

export default EditTemplateModal;
