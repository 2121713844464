import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import ReactTags from 'react-tag-autocomplete';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Contacts from '../../../Interfaces/contacts';
import { Deal } from '../../../Interfaces/deal';
import User from '../../../Interfaces/user';
import { State, useAppDispatch } from '../../../store';
import { getAllAdditionalContacts, selectAllContacts, selectDealId, selectErrorMessages, selectSendCount } from '../../../store/Communications/selectors';
import { getDealFromId } from '../../../store/Deal/actions';
import { selectDeal } from '../../../store/Deal/selector';
import { DataSenderPlaybookObject, Playbook, PlaybookTemplateConnection } from '../../../store/Playbooks/actions';
import { selectSelectedPlaybook } from '../../../store/Playbooks/selectors';
import { getUser } from '../../../store/User/actions';
import { selectUser } from '../../../store/User/selectors';
import './PlaybookCommunication.scss';
import { renderDjangoTemplate } from '../../../utils/templateHelpers';
import StepDots from '../../StepDots';
import { EmailCommunicationObject, getContactsFromId, sendCommunicationEmailToContacts, sendCommunicationSMSToContacts, SMSCommunicationObject } from '../../../store/Communications/actions';
import { checkContactsForSMSOptOut, waitForElm } from '../../../utils/helpers';
import { removeSelectedPlaybook } from '../../../store/Playbooks/reducer';
import { resetSuccessState, setEditingObject, setEditingType, setOpenEditFieldsModal } from '../../../store/EditingFields/reducer';
import EditFieldsModal, { FieldObjectTypes } from '../EditFieldsModal/EditFieldsModal';
import { selectEditingFieldObject, selectEditingType, selectOpenEditFieldsModal, selectSuccessfullyUpdatedFields } from '../../../store/EditingFields/selectors';
import { resetErrors, resetStateFlags } from '../../../store/Communications/reducer';
import DataSenderFormModal from '../DataSenderFormModal/DataSenderFormModal';
import { resetDataStates } from '../../../store/DataSender/reducer';
import { useAuthenticationStatus } from '../../hooks';
import Toggle from '../../Toggle/Toggle';

const Unlocked = () => (
  <svg width="123" height="117" viewBox="0 0 123 117" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M74.2899 79.9099L74.3606 44.7449H37.6256H0.890564L0.44751 79.9099C0.51821 100.238 17.0165 116.736 37.3685 116.736C57.7205 116.736 74.2895 100.261 74.2895 79.9099H74.2899Z" fill="#EE7878"/>
    <path d="M37.3599 61.8699C33.3471 61.8699 30.1123 65.1047 30.1123 69.1175C30.1123 72.0418 31.818 74.577 34.3083 75.7294V93.8164H41.2063V75.2844C43.2595 74.0005 44.6394 71.7176 44.6394 69.1176C44.6394 65.1048 41.3728 61.87 37.36 61.87L37.3599 61.8699Z" fill="#BB1D1D"/>
    <path d="M59.2998 42.1299C59.2998 42.1299 57.7585 0.000976562 91.1538 0.000976562C124.549 0.000976562 122.494 42.1299 122.494 42.1299H113.76C113.76 42.1299 113.503 10.276 91.1537 9.50595C68.8047 8.73529 70.0887 42.1299 70.0887 42.1299H59.2997H59.2998Z" fill="#EE7878"/>
  </svg>
)

const Locked = () => (
  <svg width="74" height="117" viewBox="0 0 74 117" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.80983 42.3399C4.80983 42.3399 3.26854 0.210938 36.6638 0.210938C70.0591 0.210938 68.0038 42.3399 68.0038 42.3399H59.2697C59.2697 42.3399 59.0129 10.4859 36.6637 9.71594C14.3147 8.94528 15.5987 42.3399 15.5987 42.3399H4.80974H4.80983Z" fill="#8FFF67"/>
    <path d="M73.8399 80.1099L73.9106 44.9448H37.1756H0.440613L-0.00244141 80.1099C0.0682586 100.438 16.5665 116.936 36.9185 116.936C57.2705 116.936 73.8395 100.461 73.8395 80.1099H73.8399Z" fill="#8FFF67"/>
    <path d="M36.91 62.0698C32.8972 62.0698 29.6624 65.3046 29.6624 69.3174C29.6624 72.2417 31.3681 74.7769 33.8584 75.9293V94.0164H40.7564V75.4843C42.8096 74.2004 44.1895 71.9176 44.1895 69.3176C44.1895 65.3048 40.9229 62.0699 36.9101 62.0699L36.91 62.0698Z" fill="#35CC00"/>
  </svg>

)

const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

const PlaybookCommunication = () => {
  const dispatch = useAppDispatch();

  const [isAuthenticated, authLoading] = useAuthenticationStatus();
  const editingObject = useSelector((state) => selectEditingFieldObject(state));
  const editingType = useSelector((state) => selectEditingType(state));
  const errors = useSelector((state) => selectErrorMessages(state));
  const self = useSelector((state: State) => state.users.self) as Self;
  // May need the templates one day. i.e when editing fields eventually so keep this.
  const [__, setTemplates] = useState([]) as PlaybookTemplateConnection[] | any;
  const [_, setDataSenders] = useState([]) as DataSenderPlaybookObject[] | any;
  // Clever user identity
  const [cleverUserIdentity, setCleverUserIdentity] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState(null) as any | PlaybookTemplateConnection;
  const [currentDataSender, setCurrentDataSender] = useState(null) as any | DataSenderPlaybookObject;
  const [ccs, setCCS] = useState([]);
  const [bccs, setBCCS] = useState([]);
  const [subject, setSubject] = useState('');
  const [roleDropdowns, setRoleDropdowns] = useState([]) as any[];
  const user = useSelector(state => selectUser(state)) as User;
  const contacts = useSelector((state) => selectAllContacts(state)) as Contacts;
  const additionalContacts = useSelector((state) => getAllAdditionalContacts(state));
  const deal = useSelector(state => selectDeal(state)) as Deal;
  const dealId = useSelector(state => selectDealId(state));
  const selectedPlaybook = useSelector((state) => selectSelectedPlaybook(state)) as Playbook;
  const successfullyUpdatedFields = useSelector((state) => selectSuccessfullyUpdatedFields(state));
  // Editing Fields
  const isEditFieldsModalOpen = useSelector((state) => selectOpenEditFieldsModal(state)) as boolean;

  const sentCount = useSelector(state => selectSendCount(state));

  const [selectedOptionForRole, setSelectedOptionForRole] = useState({}) as any;
  const [lockRoles, setLockRoles] = useState(false);
  const [rawText, setRawText] = useState('');
  const [content, setContent] = useState('');

  // Send To Array
  const [sendingInfo, setSendingInfo] = useState([]) as any[]
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [loadedConfig, setLoadedConfig] = useState(false);
  // SMS Features
  const [useMessagingService, setUseMessagingService] = useState(false);

  // Wysiwyg setup
  const editorRef = useRef() as any;
  const wysiRef = useRef(null) as any;
  const { ClassicEditor, CKEditor } = editorRef.current || {};
  const displayTemplateOptions = currentTemplate && selectedOptionForRole;
  const isCurrentTemplateEmail = currentTemplate && currentTemplate.template && currentTemplate.template.tags.indexOf('email') > -1

  const [currentStep, setCurrentStep] = useState(0);
  const [currentDataSenderStep, setCurrentDataSenderStep] = useState(0);
  const [invalidContact, setInvalidContact] = useState(false);
  const [updatedTemplates, setUpdatedTemplates] = useState([]) as any[];
  const [updatedSubject, setUpdatedSubject] = useState([]) as any[];
  const [editingKey, setEditingKey] = useState('');

  const [successfulMessage, setSuccessfulMessage] = useState(false);

  useEffect(() => {
    if (sentCount !== 0 && sentCount === sendingInfo.length) {
      setSuccessfulMessage(true);
      clearPlaybook();
      toast('Successfully sent all communications', { type: 'success', position: 'top-center' })
      setTimeout(() => {
        setSuccessfulMessage(false);
        dispatch(resetStateFlags());
      }, 30000)
    }
  }, [sentCount])

  useEffect(() => {
    if (errors) {
      toast(errors, { type: 'error' });
      dispatch(resetErrors());
    }
  }, [errors]);

  useEffect(() => {
    if (authLoading) {
      return;
    }
    if (!user && isAuthenticated && self) {
      dispatch(getUser());
    }
    if (!editorRef.current) {
      editorRef.current = {
        CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
        ClassicEditor: require('@cleverrealestate/ckeditor5-build-classic')
      }
      setLoadedEditor(true)
    }
  }, [authLoading, isAuthenticated, self])

  useEffect(() => {
    if (loadedEditor && ClassicEditor) {
      ClassicEditor.customTypes = ['footnotes']
      ClassicEditor.defaultConfig = {
        ...ClassicEditor.defaultConfig,
        placeholderConfig: {
          types: ClassicEditor.customTypes,
        }
      }
      setLoadedConfig(true);
    }
  }, [loadedEditor])

  useEffect(() => {
    if (dealId) {
      dispatch(getDealFromId(dealId));
    }
  }, [dealId])

  useEffect(() => {
    if (deal) {
      setCleverUserIdentity(deal.account_executive ? `${deal.account_executive} from Clever` : 'Clever Home Concierge')
    }
  }, [deal])

  useEffect(() => {
    if (currentStep > selectedPlaybook.templates.length - 1) {
      sendAllCommunications();
    }
  }, [currentStep, sendingInfo])

  useEffect(() => {
    if (selectedPlaybook && user) {
      // Get all Templates
      setTemplates(selectedPlaybook.templates);
      setDataSenders(selectedPlaybook.data_senders);
      let selectedOptions = {};
      // Turn roles into a dropdown based off the contacts from the deal
      const dropdowns = Object.keys(selectedPlaybook.roles).map((key) => {
        // return label, type and dropdown values
        // Value Type = Agent/Deal/Loan Officer/Customer/User etc
        const valueType = (selectedPlaybook.roles as any)[key];
        let values = [] as any[];
        let preselectedOption = {};
        switch (valueType) {
          case 'Cash Buyer':
            if (!additionalContacts || !additionalContacts.cash_buyers) {
              toast('Missing Cash Buyer Role(s)!', { type: 'error' })
              break;
            }
            values = values.concat(additionalContacts.cash_buyers.map((cb: any) => {
              return {
                label: `${cb.firstname} ${cb.lastname}`,
                value: cb,
              }
            }))
            if (additionalContacts.cash_buyers.length === 1) {
              preselectedOption = {
                [key]: {label: `${additionalContacts.cash_buyers[0].firstname} ${additionalContacts.cash_buyers[0].lastname}`, value: additionalContacts.cash_buyers[0] }
              }
            }
            break;
          case 'Agent':
            if (!additionalContacts || !additionalContacts.agents) {
              toast('Missing Agent Role(s)!', { type: 'error' })
              break;
            }
            values = values.concat(additionalContacts.agents.map((ag: any) => {
              return {
                label: `${ag.firstname} ${ag.lastname}`,
                value: ag,
              }
            }))
            if (additionalContacts.agents.length === 1) {
              preselectedOption = {
                [key]: {label: `${additionalContacts.agents[0].firstname} ${additionalContacts.agents[0].lastname}`, value: additionalContacts.agents[0] }
              }
            }
            break;
          case 'Loan Officer':
            if (!additionalContacts || !additionalContacts.loanOfficers) {
              toast('Missing Loan Officer Role(s)!', { type: 'error' })
              break;
            }
            values = values.concat(additionalContacts.loanOfficers.map((lo: any) => {
              return {
                label: `${lo.firstname} ${lo.lastname}`,
                value: lo,
              }
            }))
            if (additionalContacts.loanOfficers.length === 1) {
              preselectedOption = {
                [key]: { label: `${additionalContacts.loanOfficers[0].firstname} ${additionalContacts.loanOfficers[0].lastname}`, value: additionalContacts.loanOfficers[0] }
              }
            }
            break;
          case 'Customer':
            if (!additionalContacts || !additionalContacts.customers) {
              toast('Missing Customer Role(s)!', { type: 'error' })
              break;
            }
            values = values.concat(additionalContacts.customers.map((c: any) => {
              return {
                label: `${c.firstname} ${c.lastname}`,
                value: c,
              }
            }))
            if (additionalContacts.customers.length === 1) {
              preselectedOption = {
                [key]: { label: `${additionalContacts.customers[0].firstname} ${additionalContacts.customers[0].lastname}`, value: additionalContacts.customers[0] }
              }
            }
            break;
          case 'User':
            if (!user) {
              toast('Missing User Role(s)!', { type: 'error' })
              break;
            }
            values.push({
              label: `${user.first_name} ${user.last_name}`,
              value: user,
            })
            preselectedOption = {
              [key]: { label: `${user.first_name} ${user.last_name}`, value: user }
            }
            break;
          default:
            if (!deal) {
              toast('Missing Deal Role(s)!', { type: 'error' })
              break;
            }
            values.push({
              label: `${deal.customer.firstname} ${deal.customer.lastname} ${deal.transaction_type} - ${deal.hubspot_deal_id}`,
              value: deal,
            })
            preselectedOption = {
              [key]: { label: `${deal.customer.firstname} ${deal.customer.lastname} ${deal.transaction_type} - ${deal.hubspot_deal_id}`, value: deal }
            }
            break;
        }
        selectedOptions = {
          ...selectedOptions,
          ...preselectedOption
        }
        return {
          label: key,
          type: (selectedPlaybook.roles as any)[key],
          values: values,
        }
      })
      // Set Role Dropdowns
      setSelectedOptionForRole(selectedOptions);
      setRoleDropdowns(dropdowns)
      setCurrentStep(0);
      setCurrentDataSenderStep(0);
      // Set First Template
      // setCurrentTemplate(selectedPlaybook.templates[0]);
    }

  }, [selectedPlaybook, contacts, user, additionalContacts])

  useEffect(() => {
    setCurrentTemplate(selectedPlaybook.templates[currentStep])
  }, [currentStep])

  useEffect(() => {
    if (selectedPlaybook && selectedPlaybook.data_senders) {
      setCurrentDataSender(selectedPlaybook.data_senders[currentDataSenderStep])
    }
  }, [currentDataSenderStep])

  const renderWysiwygFromTemplate = async () => {
    if (currentTemplate
      && selectedOptionForRole
      && displayTemplateOptions
      && lockRoles
    ) {

      const roleTranslations = currentTemplate.roles_translation || {};
      const template = currentTemplate.template;
      const templateRoles = template.roles; // Use this for the keys
      // set the values equal to the role translation objects
      const renderedContext = {} as any;
      // template context = ["agent_1", "user"]
      // role Translations = { agent_1: "winning_agent_1", user: "user" }
      // selectedOption for roles = { user: { label: '', value: object }}
      Object.keys(templateRoles).forEach((contextKey: string) => {
        const key = contextKey;
        if (Object.keys(roleTranslations).length === 0) {
          // No translations use the template roles
          renderedContext[key] = selectedOptionForRole[key] ? selectedOptionForRole[key].value : null
        } else {
          const translatableKey = roleTranslations[key];
          renderedContext[key] = selectedOptionForRole[translatableKey] ? selectedOptionForRole[translatableKey].value : null
        }
      })
      // Check if the send to contact has sms opt in
      // currentTemplate.template.tags.indexOf('sms') > -1
      const ctx = getCurrentTemplateSendToValue();
      const { notOptedInFlag, names } = checkContactsForSMSOptOut(currentTemplate.template, ctx)
      // let NOT_OPTED_FLAG = false
      // let OPTED_NAMES = '';
      // const isSMS = currentTemplate.template.tags.indexOf('sms') > -1;
      // if (isSMS) {
      //   // Check to see if contact is legit
        // const contacts = getCurrentTemplateSendToValue();
      //   contacts.forEach((contactOption: any) => {
      //     if (contactOption.value && contactOption.value.sms_opt_out) {
      //       NOT_OPTED_FLAG = true;
      //       OPTED_NAMES += ` ${contactOption.value.firstname}`
      //     } else if (contactOption.value && !contactOption.value.text_consent_opt_in) {
      //       NOT_OPTED_FLAG = true;
      //       OPTED_NAMES += ` ${contactOption.value.firstname}`
      //     }
      //   })
      // }
      if (notOptedInFlag) {
        setInvalidContact(true);
        toast(`WARNING: One or more of the contacts ( ${names} ) that will receive one of the communications are opted out of sms or has not opted in to text messages. Please re-select a playbook that does not send this contact an sms OR skip this template.`, { type: 'error', position: 'top-center' })
        // window.alert(`WARNING: One or more of the contacts ( ${OPTED_NAMES} ) that will receive one of the communications are opted out of sms or has not opted in to text messages. Please re-select a playbook that does not send this contact an sms OR skip this template.`)
      } else {
        setInvalidContact(false);
      }

      const templateSubject = renderDjangoTemplate(currentTemplate?.template?.subject || '', renderedContext);
      setSubject(templateSubject);
      let content = currentTemplate.template.template;
      // If there already is an updated template use that
      if (updatedTemplates.length > currentStep && updatedTemplates[currentStep]) {
        content = updatedTemplates[currentStep];
      }
      const output = renderDjangoTemplate(content, renderedContext);
      const html = getFootnote();
      let newOutput = output
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, ' ')
        .replaceAll(/&lt;/gm, '<')
        .replaceAll(/&gt;/gm, '>'); // mainly for sms templates

      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
      // Wait for element to become available
      const domEditableElement = await waitForElm('.ck-editor__editable');
      // Get the editor instance from the editable element.
      if (domEditableElement && newOutput) {
        const editorInstance = (domEditableElement as any).ckeditorInstance;
        if (editorInstance) {
          // Use the editor instance API.
          if (!isCurrentTemplateEmail) {
            editorInstance.setData( newOutput.replaceAll(/\n/g, '<br>') );
            setContent(newOutput.replaceAll(/\n/g, '<br>'));
          } else {
            editorInstance.setData( newOutput );
            setContent(newOutput);
          }

          const plainText = viewToPlainText(editorInstance.editing.view.document.getRoot());
          setRawText(plainText);
        }
      }
    }
  }

  // Render Wysiwyg Template
  useEffect(() => {
    renderWysiwygFromTemplate();
  }, [currentTemplate, selectedOptionForRole, displayTemplateOptions, lockRoles])

  const getCurrentTemplateSendToValue = () => {
    if (!currentTemplate) {
      return [];
    }
    const sendToItems = currentTemplate.send_to;
    const sendToValues = sendToItems.map((item: string) => {
      return selectedOptionForRole && selectedOptionForRole[item] ? selectedOptionForRole[item] : null;
    }).filter((val:any) => val !== null)

    return sendToValues;
  };

  const handleOptionRoleChange = (key: string, value: any) => {

    const newSelectedOptionForRole = {
      ...selectedOptionForRole,
      [key]: value,
    }
    setSelectedOptionForRole(newSelectedOptionForRole);
  }

  const onDelete = (i: any) => {
    const newTags = ccs.slice(0);
    newTags.splice(i, 1);
    setCCS(newTags);
  }

  const onAddition = (tag: any) => {
    const newTags = [].concat(ccs, tag);
    setCCS(newTags);
  }

  const onDeleteBcc = (i: any) => {
    const newTags = bccs.slice(0);
    newTags.splice(i, 1);
    setBCCS(newTags);
  }

  const onAdditionBCC = (tag: any) => {
    const newTags = [].concat(bccs, tag);
    setBCCS(newTags);
  }

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onValidate = (tag: any) => {
    return validateEmail(tag.name);
  }

  const handleBlur = (_: any, editor: any) => {
    const value = editor.getData();
    const html = getFootnote();
    let newOutput = value
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, ' ');
    newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
    editor.setData(newOutput);
    let plainText = viewToPlainText(editor.editing.view.document.getRoot());
    setRawText(plainText);
  };

  const getFootnote = () => {
    let html = '';
    if (isCurrentTemplateEmail && user) {
      if (user.signature_link) {
        html = `<p>Best,</p>
          <a href="https://listwithclever.com"><img src="${user.signature_link}" alt="${user.first_name}" style="width: 644px;" width="644" height="187" /></a>`
      } else {
        html = `<p>Best,</p>
          <p>${user ? `${user.first_name} ${user.last_name || ''}` : ''}<br/>
            Clever Concierge<br/>
            <a href="tel: ${user.phone || '614-363-2845'}">${user.phone ? formatPhoneNumber(user.phone) : '614-363-2845'}</a><br/>
            <a href="mailto: team@movewithclever.com">team@movewithclever.com</a><br/>
            <a href="https://www.listwithclever.com/">www.listwithclever.com</a><br/>
            See our reviews on<br/>
            <img src="https://emailsignature.trustpilot.com/logo/s/1/logo@2x.png" srcset="https://emailsignature.trustpilot.com/logo/s/1/logo@2x.png" style="width:128px" width="128" /><br/>
            <img src="https://emailsignature.trustpilot.com/signature/en-US/1/5a95a55a4e08e80001875736/stars.png" srcset="https://emailsignature.trustpilot.com/signature/en-US/1/5a95a55a4e08e80001875736/stars.png" width="128" style="width:128px" /><br/>
            MO Real Estate Agent<br/>
        </p>`;  
      }
    } else {
      html = `Best,<br>
        ${user ? `${user.first_name} ${user.last_name || ''}` : ''}<br>
          Clever Concierge<br>
          ${user.phone || '614-363-2845'}<br>
          ${user.email || 'team@movewithclever.com'}<br>`;   
    }
    return html;
  }

  const handleTemplateChange = (_: any, editor: any) => {
    if (editor) {
      const value = editor.getData();
      if (value.indexOf('{{ footnotes }}') > -1) {
        const html = getFootnote();
        let newOutput = value
            .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
            .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
            .replaceAll(/&nbsp;/gm, ' ');
        newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
        editor.setData(newOutput);
        let plainText = viewToPlainText(editor.editing.view.document.getRoot());
        setRawText(plainText);
      } else {
        const plainText = viewToPlainText(editor.editing.view.document.getRoot());
        setRawText(plainText);
        setContent(value);
      }
    }
  }

  const getUpdatedCommList = () => {
    const selectedContacts = getCurrentTemplateSendToValue();
    const newSendingInfo = [...sendingInfo];
    
    if (isCurrentTemplateEmail) {
      let newOutput = content
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, ' ');
      const html = getFootnote();    
      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
      
      const contactIds = selectedContacts.map((contact: any) => {
        if (contact.value.vid) {
          return contact.value.vid;
        } else {
          return contact.value.id;
        }
      });
      const emailCommunication = {
        to: contactIds,
        subject: subject,
        body: newOutput,
        cc: ccs.map((tag: any) => tag.name),
        bcc: bccs.map((tag: any) => tag.name),
      } as EmailCommunicationObject
      // Splice element if already exists
      if (newSendingInfo[currentStep]) {
        // Handle changes to content
        const newUpdatedTemplates = [...updatedTemplates];
        newUpdatedTemplates.splice(currentStep, 1, newOutput)
        setUpdatedTemplates(newUpdatedTemplates);

        const newUpdatedSubject = [...updatedSubject];
        newUpdatedSubject.splice(currentStep, 1, subject)
        setUpdatedSubject(newUpdatedSubject);
        // Update Sending Info
        newSendingInfo.splice(currentStep, 1, emailCommunication);
      } else {
        // Handle Changes to content
        const newUpdatedTemplates = [...updatedTemplates];
        newUpdatedTemplates.push(newOutput);
        setUpdatedTemplates(newUpdatedTemplates);

        const newUpdatedSubject = [...updatedSubject];
        newUpdatedSubject.push(subject)
        setUpdatedSubject(newUpdatedSubject);
        // Update Sending Info
        newSendingInfo.push(emailCommunication);
      }
    } else {
      let newOutput = rawText
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, ' ');
      const html = getFootnote()    
      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)

      const contactIds = selectedContacts.map((contact: any) => {
        if (contact.value.vid) {
          return contact.value.vid;
        } else {
          return contact.value.id;
        }
      });
      const smsCommunication = {
        to: contactIds,
        body: newOutput,
        use_messaging_service: contactIds.length > 1 ? useMessagingService : false,
        clever_user_identity: cleverUserIdentity,
      } as SMSCommunicationObject
      if (newSendingInfo[currentStep]) {
        // Handle changes to content
        const newUpdatedTemplates = [...updatedTemplates];
        newUpdatedTemplates.splice(currentStep, 1, newOutput)
        setUpdatedTemplates(newUpdatedTemplates);

        const newUpdatedSubject = [...updatedSubject];
        newUpdatedSubject.splice(currentStep, 1, null)
        setUpdatedSubject(newUpdatedSubject);
        // Update Sending Info
        newSendingInfo.splice(currentStep, 1, smsCommunication);
      } else {
        // Handle Changes to content
        const newUpdatedTemplates = [...updatedTemplates];
        newUpdatedTemplates.push(newOutput);
        setUpdatedTemplates(newUpdatedTemplates);

        const newUpdatedSubject = [...updatedSubject];
        newUpdatedSubject.push(null)
        setUpdatedSubject(newUpdatedSubject);
        // Update Sending Info
        newSendingInfo.push(smsCommunication);
      }
    }
    return newSendingInfo;
  }

  const nextTemplate = () => {
    // Create a communication object (either email or sms)
    // Set next template as the current template
    const updatedSendingInfo = getUpdatedCommList();
    setSendingInfo(updatedSendingInfo);
    setCurrentStep(currentStep + 1);
    // INTERNAL LOGGING
    if (typeof window !== 'undefined') {
      if (!(window as any).logs) {
        (window as any).logs = {
          sendingInfo: updatedSendingInfo,
        };
      } else {
        (window as any).logs.sendingInfo = updatedSendingInfo;
      }
    }
  };

  const nextTemplateSkip = () => {
    setInvalidContact(false);
    setCurrentStep(currentStep + 1);
    // INTERNAL LOGGING
    if (typeof window !== 'undefined') {
      if (!(window as any).logs) {
        (window as any).logs = {
          sendingInfo: sendingInfo,
        };
      } else {
        (window as any).logs.sendingInfo = sendingInfo;
      }
    }
    if (currentStep === selectedPlaybook.templates.length - 1) {
      sendAllCommunications();
    } 
  }
  
  const previousTemplate = () => {
    const updatedSendingInfo = getUpdatedCommList();
    setSendingInfo(updatedSendingInfo);
    setCurrentStep(currentStep - 1);
    // LOGGING
    if (typeof window !== 'undefined') {
      if (!(window as any).logs) {
        (window as any).logs = {
          sendingInfo: updatedSendingInfo
        };
      } else {
        (window as any).logs.sendingInfo = updatedSendingInfo;
      }
    }
  }

  const sendAllCommunications = () => {
    // Gone through all templates

    sendingInfo.forEach((communication: any) => {
      if (Object.keys(communication).indexOf('subject') > -1) {
        // EMAIL TEMPLATE
        dispatch(sendCommunicationEmailToContacts(communication));
      } else {
        // SMS Template
        dispatch(sendCommunicationSMSToContacts(communication));
      }
    })
  }

  const lockAllRoles = () => {
    if (lockRoles) {
      setLockRoles(false);
    } else {
      // Check to see that all the roles have been filled out.
      if (roleDropdowns.length === Object.keys(selectedOptionForRole).length) {
        // Its ok to lock em
        setLockRoles(true);
      } else {
        toast("Please make sure you've selected all the roles before continuing. If you want to fill out a dropdown but there is no option, you will need to select or create a new template within the playbook.", { type: 'warning' })
        // window.alert("Please make sure you've selected all the roles before continuing. If you want to fill out a dropdown but there is no option, you will need to select or create a new template within the playbook.")
      }
    }
  }

  const clearPlaybook = () => {
    setInvalidContact(false);
    dispatch(removeSelectedPlaybook());
  }

  const openEditFieldsModal = (option: any, label: string) => {
    const fieldType = (selectedPlaybook.roles as any)[label];
    switch(fieldType) {
      case 'Agent':
        dispatch(setEditingType(FieldObjectTypes.Agent))
        break;
      case 'Customer':
        dispatch(setEditingType(FieldObjectTypes.Customer));
        break;
      case 'Loan Officer':
        dispatch(setEditingType(FieldObjectTypes['Loan Officer']));
        break;
      case 'Deal':
        dispatch(setEditingType(FieldObjectTypes.Deal));
        break;
      case 'Cash Buyer':
        dispatch(setEditingType(FieldObjectTypes['Cash Buyer']));
      default:
        return;
    }
    setEditingKey(label);
    dispatch(setEditingObject(option.value))
    dispatch(setOpenEditFieldsModal(true))
  }

  useEffect(() => {
    if (successfullyUpdatedFields) {
      dispatch(resetSuccessState());
      let label = '';
      switch (editingType) {
        case FieldObjectTypes.Agent:
          label = `${editingObject.firstname} ${editingObject.lastname}`
          break;
        case FieldObjectTypes.Customer:
          label = `${editingObject.firstname} ${editingObject.lastname}`
          break;
        case FieldObjectTypes['Loan Officer']:
          label = `${editingObject.firstname} ${editingObject.lastname}`
          break;
        case FieldObjectTypes['Cash Buyer']:
          label = `${editingObject.firstname} ${editingObject.lastname}`
          break;
        default:
          label = `${editingObject.id}`
          break;
      }
      const newSelectedOptionForRole = {
        ...selectedOptionForRole,
        [editingKey]: { label: `${label}`, value: editingObject },
      }
      setSelectedOptionForRole(newSelectedOptionForRole);

      if (dealId) {
        dispatch(getContactsFromId(dealId));
      }
    }
  }, [successfullyUpdatedFields])

  const handleNextDataSender = () => {
    dispatch(resetDataStates());
    setCurrentDataSenderStep(currentDataSenderStep + 1);
  }

  const handleDSSkip = () => {
    dispatch(resetDataStates());
    setCurrentDataSenderStep(currentDataSenderStep + 1);
  }

  // selectedContacts.length > 1 then display account executive && !isCurrentTemplateEmail
  const displayCleverUserIdentity = useMemo(() => {
    const templates = getCurrentTemplateSendToValue();
    if (templates && templates?.length > 1 && !isCurrentTemplateEmail) {
      return true;
    }
    return false;
  }, [isCurrentTemplateEmail, currentTemplate]);

  return (
    <div className="playbookCommunicationContainer">
      <div className="playbookRoles">
        <p className="selectRoleTitle">Select the playbook roles <button className="lockButton" onClick={() => lockAllRoles()}>{lockRoles ? <Locked /> : <Unlocked />}</button> <button className="deleteButton" onClick={() => clearPlaybook()}>Clear Playbook</button></p>
        {currentTemplate && roleDropdowns.length > 0 && (
          <div className="selectList">
            {/* dropdown: { label: '', type: '', values: []} */}
            {roleDropdowns.map((dropdown: any, index: any) => (
              <div key={`role-selector-${index}`} className="dropdownContainer">
                <label>{dropdown.label}</label>
                <Select
                  className="defaultSelect"
                  value={selectedOptionForRole ? selectedOptionForRole[dropdown.label] : null}
                  options={dropdown.values}
                  onChange={(value) => handleOptionRoleChange(dropdown.label, value)}
                  isDisabled={lockRoles}
                />
                {lockRoles && (selectedPlaybook.roles as any)[dropdown.label] !== 'User' && (
                  <div className="editButtonField">
                    <button className="smallEditButton" onClick={() => openEditFieldsModal(selectedOptionForRole ? selectedOptionForRole[dropdown.label] : null, dropdown.label)}>Edit</button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {displayTemplateOptions && lockRoles && (
          <div className="templateOptionsContainer">
            <div className="templateOptionDropdowns">
              <div className="sendToContainer">
                <label>Send To</label>
                <Select
                  value={getCurrentTemplateSendToValue()}
                  isMulti
                  isDisabled
                />
              </div>
            </div>
            {!isCurrentTemplateEmail && (
              <div style={{ margin: '1rem 0'}}>
                <label>Use Messaging Service (experimental feature)</label>
                <Toggle onClick={() => setUseMessagingService(!useMessagingService)} on={useMessagingService} />
                <p style={{ fontSize: '0.65rem' }}><b style={{ fontWeight: 'bolder' }}>NOTE: </b>The "Use Messaging Service" will use an external Messaging Service (that internally uses Twilio Provider) for sending Group Text SMSs , instead of using Bandwidth Provider. This param will be ignored if only one contact is selected for sending to.</p>
              </div>
            )}
            {displayCleverUserIdentity && (
              <div style={{ margin: '0.5rem 0',  display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontSize: '0.875rem'}}>Clever User Identity <small>(for manually sending group texts)</small></label>
                <input style={{
                  fontSize: '1rem',
                  padding: '0.5rem',
                }} className="subject" value={cleverUserIdentity} onChange={(e) => setCleverUserIdentity(e.target.value)} />
              </div>
            )}
            {isCurrentTemplateEmail && (
              <div className="ccContainer">
                <label>CC</label>
                <ReactTags
                  placeholderText="Add CC"
                  tags={ccs}
                  allowNew
                  suggestions={[]}
                  onDelete={onDelete}
                  onAddition={onAddition}
                  onValidate={onValidate}
                />
              </div>
            )}
            {isCurrentTemplateEmail && (
              <div className="ccContainer">
                <label>BCC</label>
                <ReactTags
                  placeholderText="Add BCC"
                  tags={bccs}
                  allowNew
                  suggestions={[]}
                  onDelete={onDeleteBcc}
                  onAddition={onAdditionBCC}
                  onValidate={onValidate}
                />
              </div>
            )}
            <div className="wysiwygContainer">
              {/* Set Up Wysiwyg */}
              {/* Check if template is email add subject */}
              {isCurrentTemplateEmail && (
                <div className="subjectContainer">
                  <label>Subject</label>
                  <input className="subject" value={subject} onChange={e => setSubject(e.target.value)} />
                </div>
              )}
            <div className="wysiwygEditorContainer">
              <label>Body</label>
              {loadedEditor && loadedConfig && (
                <CKEditor
                  ref={wysiRef}
                  onBlur={handleBlur}
                  editor={ClassicEditor}
                  data={content || ''}
                  onChange={handleTemplateChange}
                />
              )}
              <StepDots numberOfSteps={selectedPlaybook.templates.length} currentStep={currentStep} />
              <div className="buttonRow">
                {currentStep !== 0 && <button className="defaultButton" onClick={() => previousTemplate()}>Previous Template</button>}
                <button className="skipButton" onClick={() => nextTemplateSkip()}>Skip</button>
                <button className="defaultButton" disabled={invalidContact} onClick={() => nextTemplate()}>{currentStep === selectedPlaybook.templates.length - 1 ? 'Send All Communications' : 'Looks Good! Go to next template.'}</button>
              </div>
            </div>
            </div>
          </div>
        )}
        
      </div>
      {isEditFieldsModalOpen && (
        <EditFieldsModal />
      )}
      {selectedPlaybook.data_senders && selectedPlaybook.data_senders.length > 0 && currentDataSenderStep < selectedPlaybook.data_senders.length && lockRoles && currentDataSender && (
        <DataSenderFormModal contacts={additionalContacts} onSkip={() => handleDSSkip()} onNext={() => handleNextDataSender()} currentDataSender={currentDataSender} />
      )}
      {/* <ToastContainer newestOnTop /> */}
      <div className="communicationDisplay">
        {successfulMessage && <p className="success">Successfully Sent All Communications</p>}
      </div>
    </div>
  )
}

export default PlaybookCommunication;
